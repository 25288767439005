import 'bootstrap'
import './sass/main.scss'

const form = document.querySelector('.contact-form')

form.addEventListener('submit', e => {
  e.preventDefault()

  const payload = {}
  const fields = form.querySelectorAll('.contact-form__input')

  fields.forEach(field => payload[field.name] = field.name)

  //fetch('/mail.php', payload)
})
